body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #292d35;
}

body {
  background-color: #292d35;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-goal {
  color: #fff;
  background-color: #90b4ba;
  border-color: #99b9ba;
}

.btn-goal-big {
  width: 12em;
  height: 10em;
  display: table-cell;
  vertical-align: middle;
}

.btn-goal:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-goal:focus,
.btn-goal.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-goal.disabled,
.btn-goal:disabled {
  color: #fff;
  background-color: #90b4ba;
  border-color: #99b9ba;
}

.btn-goal:not(:disabled):not(.disabled):active,
.btn-goal:not(:disabled):not(.disabled).active,
.show > .btn-goal.dropdown-toggle {
  color: #fff;
  background-color: #90b4ba;
  border-color: #99b9ba;
}

.btn-goal:not(:disabled):not(.disabled):active:focus,
.btn-goal:not(:disabled):not(.disabled).active:focus,
.show > .btn-goal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-goal-done {
  color: #fff;
  background-color: #00b4ba;
  border-color: #99b9ba;
}

.goal-card a {
  color: inherit;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.navbar-item {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-height: 5rem;
}

